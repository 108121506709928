<template>
  <div class="calculator">
    <Header currentPage="calculator"></Header>
    <div class="calculator__content">
      <div v-if="!result" class="calculator__calc calc">
        <h1 class="calc__title">Калькулятор ризику РСС<sup>1</sup></h1>
        <div class="calc__description">Цей калькулятор розрахований на пацієнтів з гіпертрофічною кардіоміопатію</div>
        <div class="calc__form">
          <div class="calc__form--item item">
            <div class="item__wrapper">
              <label class="calc__question" for="age">
                Вік пацієнта
              </label>
              <input
                type="number"
                name="age"
                v-model="age.value"
                id="age"
                placeholder="16-80"
                min="16"
                max="80"
                class="calc__field"
                :class="{error: age.error === true}"
                @blur="changeInput('age')"
              >
              <div v-if="age.error" class="error-msg">{{ age.messageError }}</div>
            </div>
          </div>
          <div class="calc__form--item item">
            <div class="item__wrapper">
              <label class="calc__question" for="wall-thickness">
                Максимальна товщина стінки ЛШ, мм
                <div class="mob__icon-wrapper">
                  <img v-if="openedHint !== '.wall-thickness'" @click="openMobHint('.wall-thickness')" class="info"
                       src="@/assets/img/calc/info.svg" alt="">
                  <img v-if="openedHint === '.wall-thickness'" @click="crossMobHint('.wall-thickness')" class="cross"
                       src="@/assets/img/calc/cross.svg" alt="">
                </div>
              </label>
              <div class="mob__reason wall-thickness">Може бути визначена за допомогою трансторакального
                ехокардіографічного вимірювання
              </div>
              <input
                type="number"
                v-model="wallThickness.value"
                name=""
                id="wall-thickness"
                placeholder="10-35"
                min="10"
                max="35"
                class="calc__field"
                :class="{error: wallThickness.error === true}"
                @blur="changeInput('wallThickness')"
              >
              <div v-if="wallThickness.error" class="error-msg">{{ wallThickness.messageError }}</div>
            </div>
            <div class="calc__reason">Може бути визначена за допомогою трансторакального ехокардіографічного
              вимірювання
            </div>
          </div>
          <div class="calc__form--item item">
            <div class="item__wrapper">
              <label class="calc__question" for="left-atrial">
                Розмір лівого передсердя, мм
                <div class="mob__icon-wrapper">
                  <img v-if="openedHint !== '.left-atrial'" @click="openMobHint('.left-atrial')" class="info"
                       src="@/assets/img/calc/info.svg" alt="">
                  <img v-if="openedHint === '.left-atrial'" @click="crossMobHint('.left-atrial')" class="cross"
                       src="@/assets/img/calc/cross.svg" alt="">
                </div>
              </label>
              <div class="mob__reason left-atrial">Діаметр лівого передсердя може бути визначений за допомогою М-режиму
                або 2D-ехокардіографії в парастернальній позиції по довгій осі
              </div>
              <input
                type="number"
                v-model="leftAtrial.value"
                name=""
                id="left-atrial"
                placeholder="28-67"
                min="28"
                max="67"
                class="calc__field"
                :class="{error: leftAtrial.error === true}"
                @blur="changeInput('leftAtrial')"
              >
              <div v-if="leftAtrial.error" class="error-msg">{{ leftAtrial.messageError }}</div>
            </div>
            <div class="calc__reason">Діаметр лівого передсердя може бути визначений за допомогою М-режиму або
              2D-ехокардіографії в парастернальній позиції по довгій осі
            </div>
          </div>
          <div class="calc__form--item item">
            <div class="item__wrapper">
              <label class="calc__question" for="gradient">
                Градієнт тиску у вихідному тракті лівого шлуночка, мм рт.ст.
                <div class="mob__icon-wrapper">
                  <img v-if="openedHint !== '.gradient'" @click="openMobHint('.gradient')" class="info"
                       src="@/assets/img/calc/info.svg" alt="">
                  <img v-if="openedHint === '.gradient'" @click="crossMobHint('.gradient')" class="cross"
                       src="@/assets/img/calc/cross.svg" alt="">
                </div>
              </label>
              <div class="mob__reason gradient">Градієнт тиску у вихідному тракті лівого шлуночка слід визначати за
                допомогою модифікованого рівняння Бернуллі: градієнт = 4V², де V — пікова швидкість току крові з аорти
              </div>
              <input
                type="number"
                name=""
                v-model="gradient.value"
                id="gradient"
                placeholder="2-154"
                min="2"
                max="154"
                class="calc__field"
                :class="{error: gradient.error === true}"
                @blur="changeInput('gradient')"
              >
              <div v-if="gradient.error" class="error-msg">{{ gradient.messageError }}</div>
            </div>
            <div class="calc__reason">Градієнт тиску у вихідному тракті лівого шлуночка слід визначати за допомогою
              модифікованого рівняння Бернуллі: градієнт = 4V², де V — пікова швидкість току крові з аорти
            </div>
          </div>
          <div class="calc__form--item item">
            <div class="item__wrapper">
              <div class="calc__question">
                Чи є в сімейному анамнезі випадок РСС?
                <div class="mob__icon-wrapper">
                  <img v-if="openedHint !== '.death'" @click="openMobHint('.death')" class="info"
                       src="@/assets/img/calc/info.svg" alt="">
                  <img v-if="openedHint === '.death'" @click="crossMobHint('.death')" class="cross"
                       src="@/assets/img/calc/cross.svg" alt="">
                </div>
              </div>
              <div class="mob__reason death">Наявний епізод РСС у родичів ≥1ступеня спорідненості у віці до 40 років або
                РСС у родичів 1 ступеня спорідненості з підтвердженням ГКМП у будь-якому віці
              </div>

              <div class="item__container">
                <div class="calc__radio">
                  <input
                    class="calc__radio--item"
                    type="radio"
                    name="death"
                    id="death-yes"
                    v-model="death.value"
                    value="1"
                    @change="changeInput('death')"
                  >
                  <label for="death-yes">
                    <div class="decor-container">
                      <div class="decor-round"></div>
                    </div>
                    <div class="text">Так</div>
                  </label>
                </div>

                <div class="calc__radio">
                  <input
                    class="calc__radio--item"
                    type="radio"
                    name="death"
                    id="death-no"
                    v-model="death.value"
                    value="0"
                    @change="changeInput('death')"
                  >
                  <label for="death-no">
                    <div class="decor-container">
                      <div class="decor-round"></div>
                    </div>
                    <div class="text">Ні</div>
                  </label>
                </div>
              </div>
            </div>

            <div class="calc__reason">Наявний епізод РСС у родичів ≥1ступеня спорідненості у віці до 40 років або РСС у
              родичів 1 ступеня спорідненості з підтвердженням ГКМП у будь-якому віці
            </div>
          </div>
          <div class="calc__form--item item">
            <div class="item__wrapper">
              <div class="calc__question">Чи є в анамнезі епізод нестійкої ШТ?</div>

              <div class="item__container">
                <div class="calc__radio">
                  <input
                    class="calc__radio--item"
                    type="radio"
                    name="non-sustained"
                    id="non-sustained-yes"
                    v-model="nonSustained.value"
                    value="1"
                    @change="changeInput('nonSustained')"
                  >
                  <label for="non-sustained-yes">
                    <div class="decor-container">
                      <div class="decor-round"></div>
                    </div>
                    <div class="text">Так</div>
                  </label>
                </div>

                <div class="calc__radio">
                  <input
                    class="calc__radio--item"
                    type="radio"
                    name="non-sustained"
                    id="non-sustained-no"
                    v-model="nonSustained.value"
                    value="0"
                    @change="changeInput('nonSustained')"
                  >
                  <label for="non-sustained-no">
                    <div class="decor-container">
                      <div class="decor-round"></div>
                    </div>
                    <div class="text">Ні</div>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="calc__form--item item">
            <div class="item__wrapper">
              <div class="calc__question">Чи була у пацієнта втрата свідомості внаслідок аритмії?</div>

              <div class="item__container">
                <div class="calc__radio">
                  <input
                    class="calc__radio--item"
                    type="radio"
                    name="syncope"
                    id="syncope-yes"
                    v-model="syncope.value"
                    value="1"
                    @change="changeInput('syncope')"
                  >
                  <label for="syncope-yes">
                    <div class="decor-container">
                      <div class="decor-round"></div>
                    </div>
                    <div class="text">Так</div>
                  </label>
                </div>

                <div class="calc__radio">
                  <input
                    class="calc__radio--item"
                    type="radio"
                    name="syncope"
                    id="syncope"
                    v-model="syncope.value"
                    value="0"
                    @change="changeInput('syncope')"
                  >
                  <label for="syncope-no">
                    <div class="decor-container">
                      <div class="decor-round"></div>
                    </div>
                    <div class="text">Ні</div>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <button
            @click="calculation"
            :disabled="disabledBtn"
            :class="{ disabled: disabledBtn }"
            class="calc__btn"
          >
            <div class="calc__btn--text">
              Розрахувати ризик
            </div>
            <div class="after calc__btn--decor">
              <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L8 8L1 15" stroke="#FB35A2" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round"/>
              </svg>
            </div>
          </button>
          <UserData
            v-if="openInfo"
            title="Калькулятор не слід використовувати для оцінки:"
            :userData="rules"
            :visibleIcon="false"
            currentPage="calculator"
            @handleInfo="handleInfo()"
            class="tableHidden"
          ></UserData>
          <UserData
            title="Калькулятор не слід використовувати для оцінки:"
            :userData="rules"
            currentPage="calculator"
            class="user-data-wrapper"
            @handleInfo="handleInfo()"
          ></UserData>
          <div class="calc__footer">
            <div class="calc__footer--item"><strong>РСС — </strong>раптова серцева смерть</div>
            <div class="calc__footer--item"><strong>ЛШ — </strong>лівий шлуночок</div>
            <div class="calc__footer--item"><strong>ШТ — </strong>шлуночкова тахікардія</div>
            <div class="calc__footer--item"><i>1. HCM Risk-SCD https://qxmd.com/calculate/calculator_303/hcm-risk-scd#
              Last access 4.10.2021</i></div>
          </div>
        </div>
      </div>
      <div
        v-if="result"
        class="calculator__result result"
        :class="{low: resultIndicator === 'low', middle: resultIndicator === 'middle', high: resultIndicator === 'high',}"
      >
        <div class="result__title">Результат розрахунку ризику РСС<sup>1</sup></div>
        <div class="result__result">
          <div class="result__result--left">5-річний ризик розвитку РСС становить</div>
          <div class="result__result--right">
            {{ result }}
            <span>%</span>
          </div>
        </div>
        <div v-if="resultIndicator === 'low'" class="result__conclusion">
          <img src="@/assets/img/calc/low.svg" alt="">
          <p>За рекомендаціями Європейського товариства кардіологів від 2014 року пацієнту імплантація
            кардіовертера-дефібрилятора <span>не показана</span></p>
        </div>
        <div v-if="resultIndicator === 'middle'" class="result__conclusion">
          <img src="@/assets/img/calc/middle.svg" alt="">
          <p>За рекомендаціями Європейського товариства кардіологів від 2014 року пацієнту імплантація
            кардіовертера-дефібрилятора <span>може бути розглянута</span></p>
        </div>
        <div v-if="resultIndicator === 'high'" class="result__conclusion">
          <img src="@/assets/img/calc/high.svg" alt="">
          <p>За рекомендаціями Європейського товариства кардіологів від 2014 року пацієнту імплантація
            кардіовертера-дефібрилятора <span>повинна бути обов'язково розглянута</span></p>
        </div>
        <button class="result__btn" @click="resetCalculation()">
          Розрахувати знов
          <img src="@/assets/img/calc/again.svg" alt="">
        </button>
        <div class="result__footer">
          <div class="calc__footer--item"><strong>РСС — </strong>раптова серцева смерть</div>
          <div class="calc__footer--item"><i>1. HCM Risk-SCD https://qxmd.com/calculate/calculator_303/hcm-risk-scd#
            Last access 4.10.2021</i></div>
        </div>
      </div>
      <transition name="fade">
        <svg
          v-if="!openInfo"
          class="calculator__icon"
          @click="handleInfo()"
          width="50"
          height="50"
          viewBox="0 0 50 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle class="calculator__icon--bg" cx="25" cy="25" r="25" fill="#285EFF"/>
          <circle cx="25" cy="12" r="3" fill="white"/>
          <path d="M25 21V37" stroke="white" stroke-width="4" stroke-linecap="round"/>
        </svg>
      </transition>
      <template v-if="result">
        <UserData
          v-if="openInfo"
          title="Дані пацієнта"
          :userData="patientData"
          @handleInfo="handleInfo()"
          class="user-data-for-result"
        ></UserData>

        <div class="user-data-for-result-icon" v-if="!openInfo">
          <svg @click="handleInfo()" width="56" height="56"
               viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="28" cy="28" r="28" fill="#285EFF"/>
            <rect x="28" y="24.6406" width="15.68" height="17.92" rx="2" stroke="white" stroke-width="2"/>
            <circle cx="22.4008" cy="17.9203" r="5.6" stroke="white" stroke-width="2"/>
            <path
              d="M28.0003 38.08H14.3203C13.2157 38.08 12.2996 37.1756 12.5162 36.0925C13.4391 31.4773 17.5136 28 22.4003 28C24.4725 28 26.3986 28.6253 28.0003 29.6974V38.08Z"
              stroke="white" stroke-width="2"/>
            <path d="M31.3594 29.1211H40.3194" stroke="white" stroke-width="2" stroke-linecap="round"/>
            <path d="M31.3594 33.5996H40.3194" stroke="white" stroke-width="2" stroke-linecap="round"/>
            <path d="M31.3594 38.0801H40.3194" stroke="white" stroke-width="2" stroke-linecap="round"/>
          </svg>
        </div>

        <div class="user-data-for-result-icon" v-if="openInfo">
          <svg @click="handleInfo()" width="56" height="56"
               viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="28" cy="28" r="28" fill="#285EFF"/>
            <path d="M18.4961 19.6172L37.5031 38.6242" stroke="white" stroke-width="2" stroke-linecap="round"/>
            <path d="M37.5039 19.6172L18.4969 38.6242" stroke="white" stroke-width="2" stroke-linecap="round"/>
          </svg>
        </div>
      </template>
    </div>
<!--    <footer>-->
<!--      © 2022 «Medtronic»-->
<!--    </footer>-->
  </div>
</template>

<script>
import Header from '../components/Header.vue'
import UserData from '@/components/UserData'
import {mapGetters, mapMutations} from 'vuex'

export default {
  name: 'Calclulator',
  components: {
    Header,
    UserData
  },
  data: () => {
    return {
      result: null,
      age: {
        value: null,
        error: false,
        messageError: 'Тут має бути значення в інтервалі 16-80'
      },
      wallThickness: {
        value: null,
        error: false,
        messageError: 'Тут має бути значення в інтервалі 10-35'
      },
      leftAtrial: {
        value: null,
        error: false,
        messageError: 'Тут має бути значення в інтервалі 28-67'
      },
      gradient: {
        value: null,
        error: false,
        messageError: 'Тут має бути значення в інтервалі 2-154'
      },
      death: {
        value: null,
        error: false,
        messageError: ''
      },
      nonSustained: {
        value: null,
        error: false,
        messageError: ''
      },
      syncope: {
        value: null,
        error: false,
        messageError: ''
      },
      disabledBtn: true,
      openInfo: false,
      rules: [
        'Педіатричних пацієнтів <nobr>(<16 років)</nobr>',
        'Професійних спортсменів',
        'Пацієнтів, які мають метаболічні захворювання (наприклад, хвороба Андерсона-Фабрі) та синдроми (наприклад, синдром Нунан)',
        'Пацієнтів, які мають в анамнезі оборотний епізод РСС або стійку шлуночкову аритмію та яким рекомендована ІКД як вторинна профілактика РСС'
      ],
      openedHint: '',
      removedHint: ''
      // patientData: [
      //   `Вік – ${this.age.value} років`,
      //   `Максимальна товщина стінки ЛШ – ${this.wallThickness.value} мм`,
      //   `Розмір лівого передсердя – ${this.leftAtrial.value} мм`,
      //   `Градієнт тиску у вихідному тракті лівого шлуночка – ${this.gradient.value} мм рт.ст`,
      //   `В сімейному анамнезі ${this.death.value === true ? 'є випадок' : 'немає випадку'} РСС`,
      //   `${this.nonSustained.value === true ? 'Є епізод' : 'Немає епізоду'} нестійкої ШТ`,
      //   `${this.syncope.value === true ? 'Була втрата' : 'Не було втрати'} свідомості внаслідок аритмії`
      // ]
    }
  },
  computed: {
    ...mapGetters([
      'getCalculatorAge',
      'getCalculatorWallThickness',
      'getCalculatorLeftAtrial',
      'getCalculatorGradient',
      'getCalculatorDeath',
      'getCalculatorNonSustained',
      'getCalculatorSyncope',
      'getCalculatorResult'
    ]),
    resultIndicator() {
      return +this.result < 4 ? 'low' : (+this.result >= 4) && (+this.result < 6) ? 'middle' : 'high'
    },
    patientData() {
      return this.age.value ? [
        `Вік – ${this.age.value} років`,
        `Максимальна товщина стінки ЛШ – ${this.wallThickness.value} мм`,
        `Розмір лівого передсердя – ${this.leftAtrial.value} мм`,
        `Градієнт тиску у вихідному тракті лівого шлуночка – ${this.gradient.value} мм рт.ст`,
        `В сімейному анамнезі ${this.death.value === '1' ? 'є випадок' : 'немає випадку'} РСС`,
        `${this.nonSustained.value === '1' ? 'Є епізод' : 'Немає епізоду'} нестійкої ШТ`,
        `${this.syncope.value === '1' ? 'Була втрата' : 'Не було втрати'} свідомості внаслідок аритмії`
      ] : []
    }
  },
  methods: {
    ...mapMutations([
      'setCalculatorAge',
      'setCalculatorWallThickness',
      'setCalculatorLeftAtrial',
      'setCalculatorGradient',
      'setCalculatorDeath',
      'setCalculatorNonSustained',
      'setCalculatorSyncope',
      'setCalculatorResult'
    ]),
    changeInput(value) {
      switch (value) {
        case 'age':
          this.validAge()
          if (!this.age.error) {
            this.setCalculatorAge(this.age.value)
          }
          break
        case 'wallThickness':
          this.validWallThickness()
          if (!this.wallThickness.error) {
            this.setCalculatorWallThickness(this.wallThickness.value)
          }
          break
        case 'leftAtrial':
          this.validLeftAtrial()
          if (!this.leftAtrial.error) {
            this.setCalculatorLeftAtrial(this.leftAtrial.value)
          }
          break
        case 'gradient':
          this.validGradient()
          if (!this.gradient.error) {
            this.setCalculatorGradient(this.gradient.value)
          }
          break
        case 'death':
          if (this.death.value) {
            this.setCalculatorDeath(this.death.value)
          }
          break
        case 'nonSustained':
          if (this.nonSustained.value) {
            this.setCalculatorNonSustained(this.nonSustained.value)
          }
          break
        case 'syncope':
          if (this.syncope.value) {
            this.setCalculatorSyncope(this.syncope.value)
          }
          break
        default:
          break
      }

      if (this.syncope.value && !this.syncope.error &&
        this.age.value && !this.age.error &&
        this.wallThickness.value && !this.wallThickness.error &&
        this.leftAtrial.value && !this.leftAtrial.error &&
        this.gradient.value && !this.gradient.error &&
        this.death.value && !this.death.error &&
        this.nonSustained.value && !this.nonSustained.error
      ) {
        this.disabledBtn = false
      }
    },
    validAge() {
      this.age.error = (this.age.value < 16 || this.age.value > 80)
    },
    validWallThickness() {
      this.wallThickness.error = (this.wallThickness.value < 10 || this.wallThickness.value > 35)
    },
    validLeftAtrial() {
      this.leftAtrial.error = (this.leftAtrial.value < 28 || this.leftAtrial.value > 67)
    },
    validGradient() {
      this.gradient.error = (this.gradient.value < 2 || this.gradient.value > 154)
    },
    calculation() {
      const index = (0.15939858 * this.wallThickness.value) - (Math.pow(this.wallThickness.value, 2) * 0.00294271) + (0.0259082 * this.leftAtrial.value) + (0.00446131 * this.gradient.value) + (0.4583082 * this.death.value) + (0.82639195 * this.nonSustained.value) + (0.71650361 * this.syncope.value) - (0.01799934 * this.age.value)
      const exp = Math.exp(index)

      this.result = Math.round((1 - Math.pow(0.998, exp)) * 100)

      this.setCalculatorResult(this.result)

      gtag('event', 'click', {'event_category': 'калькулятор: розрахунок'})
    },
    handleInfo() {
      this.openInfo = !this.openInfo
    },
    openMobHint(item) {
      const mobReason = document.querySelector(item)
      this.openedHint = item

      mobReason.classList.add('open')
    },
    crossMobHint(item) {
      this.openedHint = ''
      this.removedHint = item
      const mobReason = document.querySelector(item)

      mobReason.classList.remove('open')
    },
    resetCalculation() {
      this.age.value = null
      this.age.error = false

      this.wallThickness.value = null
      this.wallThickness.error = false

      this.leftAtrial.value = null
      this.leftAtrial.error = false

      this.gradient.value = null
      this.gradient.error = false

      this.death.value = null
      this.death.error = false

      this.nonSustained.value = null
      this.nonSustained.error = false

      this.syncope.value = null
      this.syncope.error = false

      this.result = null
    }
  }
}
</script>

<style lang="scss" scoped>
$fontR: "Montserrat-Regular";
$fontM: "Montserrat-Medium";
$fontB: "Montserrat-Bold";
$fontSB: "Montserrat-SemiBold";

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.calculator {
  &__content {
    margin-top: 120px;
    width: 100%;
    padding: 0 5%;
    text-align: left;
    display: grid;
    grid-template-columns: 65% 30%;
    grid-column-gap: 5%;
    position: relative;

    .user-data__item {
      flex-direction: row;
    }
  }

  &__icon {
    position: absolute;
    // top: 107px;
    top: 74px;
    cursor: pointer;
    right: 99px;
    transition: transform .2s ease;

    &:hover {
      transform: scale(1.2);
    }

    &:active {
      transform: scale(1.2);

      .calculator__icon--bg {
        fill: #1646D6;
      }
    }
  }

  .user-data {
    top: 50px;
  }

  .user-data-wrapper {
    right: 0;
    position: relative;

    &.user-data {
      box-shadow: none;
      border-radius: 0;
      padding: 0;
      top: 0;
      display: none;
      z-index: unset;
    }
  }

  .user-data-for-result {
    // position: relative
  }

  .user-data-for-result-icon {
    position: absolute;
    bottom: 10px;
    right: 20px;
    display: none;
    box-shadow: 0 5px 15px 0 #00000026;
    border-radius: 50%;
    z-index: 10;
    user-select: none;
    outline: none;

    svg {
      display: block;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .calc {
    &__title {
      font-family: $fontM;
      font-weight: 500;
      font-size: 32px;
      line-height: 39px;
      color: #FB35A2;
      margin-bottom: 10px;
    }

    &__description {
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 30px;
      width: 76%;
    }

    &__question {
      font-family: $fontSB;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 17px;
    }

    &__form {
      .item {
        margin-bottom: 30px;
        display: flex;

        &__wrapper {
          display: flex;
          flex-direction: column;
          width: 52%;
          min-width: 280px;

          .error-msg {
            text-align: center;
            font-family: $fontM;
            font-weight: 500;
            font-size: 10px;
            line-height: 12px;
            color: #E1425F;
            // margin-top: 5px;
            transform: translateY(5px);
          }
        }

        &__container {
          display: flex;
          justify-content: space-between;
        }
      }
    }

    &__reason {
      font-family: $fontM;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      margin-left: 30px;
      min-width: 30%;
      max-width: 38%;
      display: flex;
      align-items: flex-end;
    }

    &__field {
      background: #FFFFFF;
      border: 2px solid rgba(40, 94, 255, 0.2);
      border-radius: 16px;
      width: 100%;
      height: 60px;
      padding: 18px 16px;
      font-size: 18px;
      line-height: 22px;
      color: rgb(60, 60, 60);
      outline: none;

      &::-webkit-input-placeholder {
        color: rgba(60, 60, 60, .4);
        font-size: 18px;
        line-height: 22px;
      }

      &::placeholder {
        color: rgba(60, 60, 60, .4);
        font-size: 18px;
        line-height: 22px;
      }

      &.error {
        border-color: #CB3210;
        color: #CB3210;
      }
    }

    &__radio {
      width: 47%;
      height: 50px;
      position: relative;

      .decor-container {
        width: 36px;
        height: 36px;
        background: transparent;
        top: 5px;
        left: 8px;
        display: flex;
        justify-content: center;
        align-items: center;

        .decor-round {
          width: 22px;
          height: 22px;
          border: 1px solid #285EFF;
          border-radius: 50%;
          background: transparent;
          position: relative;
          z-index: 2;

          &::before {
            content: '';
            position: absolute;
            width: 16px;
            height: 16px;
            background: transparent;
            border-radius: 50%;
            top: 2px;
            left: 2px;
          }
        }
      }

      label {
        font-family: $fontSB;
        font-weight: 600;
        font-size: 16px;
        line-height: 110%;
        color: #285EFF;
        width: 100%;
        height: 100%;

        border-radius: 16px;
        border: 2px solid rgba(40, 94, 255, 0.2);

        position: absolute;
        top: 0;
        left: 0;

        display: flex;
        align-items: center;
      }

      &--item {
        width: 100%;
        height: 100%;
        opacity: 0;
        border: 1px solid #285EFF;
        border-radius: 50%;
        position: relative;
        cursor: pointer;
        z-index: 1;
        background-color: transparent;
        border: 2px solid rgba(40, 94, 255, 0.2);

        &:checked ~ label {
          background-color: #F1F5FF;
        }

        &:checked ~ label > .decor-container > .decor-round::before {
          background-color: rgba(40, 94, 255, .6);
        }
      }
    }

    &__btn {
      background: #FB35A2;
      color: #FFFFFF;
      box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
      border-radius: 50px;
      border-color: transparent;
      width: 280px;
      height: 50px;
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;

      &--text {
        color: #FFFFFF;
        font-weight: 500;
        font-size: 16px;
        line-height: 110%;
        font-family: $fontM;
        position: relative;
        z-index: 2;
        transform: translateX(48px);
        transition: all .5s ease-in-out;
      }

      &--decor {
        border: 1px solid #FFFFFF;
        border-radius: 50px;
        width: 30px;
        height: 30px;
        background-color: #FFFFFF;
        position: absolute;
        right: 10px;
        top: 0;
        bottom: 0;
        margin: auto 0;
        display: block;
        transition: all .5s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          position: relative;
          display: block;
        }

        &.after {
          transition: all .5s ease-in-out;
        }
      }

      &.disabled {
        cursor: default;
        opacity: .2;
      }

      &:not(.disabled) {

        &:hover {
          .calc__btn--text {
            transform: translateX(60px);
            @media screen and (max-width: 540px) {
              transform: translateX(40px) !important;
            }
          }

          .calc__btn--decor {
            right: calc(100% - 40px);

            @media screen and (max-width: 540px) {
              right: calc(100% - 29px);
            }
          }
        }

        &:active {
          background-color: #DC1B85;
        }
      }
    }

    &__footer {
      margin-top: 96px;
      padding-bottom: 20px;
      z-index: 4;
      background-color: #fff;

      &--item {
        font-family: $fontM;
        font-size: 12px;
        line-height: 15px;

        strong {
          font-family: $fontB;
          font-weight: 700;
        }

        &:last-child {
          margin-top: 18px;
        }
      }
    }

    .mob {
      &__icon-wrapper {
        display: none;
      }

      &__reason {
        display: none;
        font-family: $fontM;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        margin-bottom: 20px;
        opacity: 0;
        transition: all .3s ease;
      }
    }
  }

  .result {
    &__title {
      font-family: $fontM;
      font-weight: 500;
      font-size: 32px;
      line-height: 39px;
      margin-top: 24px;
      margin-bottom: 20px;
    }

    &__result {
      width: 100%;
      height: 100px;
      border-radius: 16px;
      border: 2px solid rgba(40, 94, 255, 0.2);
      display: flex;
      justify-content: space-between;

      &--left {
        font-size: 24px;
        line-height: 29px;
        font-family: $fontB;
        font-weight: 700;
        text-align: center;
        margin-left: 30px;
        margin-right: 10px;
        display: flex;
        align-items: center;
      }

      &--right {
        width: 22%;
        height: 100%;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $fontB;
        font-weight: 700;
        font-size: 48px;
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;

        position: relative;
        bottom: 2%;
        right: -1px;
        height: 104%;

        span {
          font-size: 36px;
        }
      }
    }

    &__conclusion {
      width: 100%;
      padding: 20px;
      border-radius: 16px;
      color: #FFFFFF;
      font-family: $fontM;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      margin-top: 10px;

      display: flex;

      span {
        text-transform: uppercase;
        font-family: $fontB;
        font-weight: 700;
      }

      img {
        margin-right: 20px;
        display: block;
        height: 40px;
        width: auto;
        margin-top: auto;
        margin-bottom: auto;
      }
    }

    &__footer {

      &--item {
        font-family: $fontM;
        font-size: 12px;
        line-height: 15px;

        strong {
          font-family: $fontB;
          font-weight: 700;
        }

        &:last-child {
          margin-top: 18px;
        }
      }
    }

    &__btn {
      box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
      border-radius: 50px;
      border: 0;
      width: 280px;
      height: 50px;
      margin: 30px auto;

      display: flex;
      align-items: center;
      justify-content: flex-end;

      font-family: $fontM;
      font-weight: 500;
      font-size: 16px;
      line-height: 110%;
      color: #3C3C3C;
      background-color: #fff;
      outline: none;
      cursor: pointer;

      img {
        margin-left: 20px;
        margin-right: 20px;
        transition: all ease .5s;
      }

      &:not(.disabled) {
        &:hover {
          img {
            transform: translateX(-210px);
          }
        }

        &:active {
          background-color: #DFE7FF;
        }
      }
    }

    &.low {
      .result__result {
        &--right {
          background-color: #10CBB5;
        }
      }

      .result__conclusion {
        background-color: #10CBB5;
      }
    }

    &.middle {
      .result__result {
        &--right {
          background-color: #F7D049;
        }
      }

      .result__conclusion {
        background-color: #F7D049;
      }
    }

    &.high {
      .result__result {
        &--right {
          background-color: #CB3210;
        }
      }

      .result__conclusion {
        background-color: #CB3210;
      }
    }
  }

  footer {
    display: none;
    background-color: #fff;
  }
}

@media screen and (max-width: 1300px) {
  .calculator {
    &__icon {
      right: 66px;
    }
  }
}

@media screen and (max-width: 1020px) {
  .calculator {
    padding-top: 78px;

    &__content {
      padding: 0 20px;
      margin-top: 20px;
    }

    &__icon {
      display: none;
    }

    footer {
      display: block;
      position: fixed;
      bottom: 0;
      width: 100%;
      left: 0;
      padding: 11px 0;
      text-align: center;
      border-top: 1px solid rgba($color: #3C3C3C, $alpha: 0.5);
      z-index: 2;
    }

    .tableHidden.user-data {
      visibility: hidden;
    }

    .user-data-wrapper.user-data {
      position: relative;
      display: block;
    }

    .user-data-for-result {
      position: fixed;
      bottom: 0;
      top: auto;
      padding-bottom: 62px;
    }

    .user-data-for-result-icon {
      display: block;
      position: fixed;
    }

    .calc {
      &__title {
        font-size: 30px;
        line-height: 37px;
      }

      &__description {
        font-size: 20px;
        line-height: 24px;
      }

      &__reason {
        margin-left: 20px;
      }

      &__btn {
        margin-bottom: 40px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .calculator {
    &__content {
      grid-template-columns: 100%;
      padding-bottom: 62px;
    }

    &__icon {
      display: none;
    }

    .calc {
      &__title {
        font-size: 30px;
        line-height: 37px;
      }

      &__description {
        font-size: 20px;
        line-height: 24px;
      }

      &__reason {
        display: none;
      }

      &__question {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-bottom: 10px;
      }

      &__btn {
        margin: 0 auto;
        margin-top: 50px;
        margin-bottom: 40px;
      }

      &__footer {
        margin-top: 40px;
        padding-bottom: 96px;
      }

      .item {
        &__wrapper {
          width: 100%;
        }
      }

      .mob {
        &__icon-wrapper {
          display: flex;
          justify-content: flex-end;

          &.info.hidden {
            display: none;
          }

          img {
            width: 28px;
            height: 28px;

            &.hidden {
              display: none;
            }
          }
        }

        &__reason {
          &.open {
            display: block;
            opacity: 1;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 540px) {
  .calculator {
    .calc {
      &__question {
        font-size: 15px;
        line-height: 18px;
      }

      &__title {
        font-size: 20px;
        line-height: 24px;
      }

      &__description {
        width: 100%;
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 20px;
      }

      &__question {
        grid-template-columns: 2fr 1fr;
      }
    }

    .result {
      &__title {
        font-size: 24px;
        line-height: 30px;
        margin-top: 0;
      }

      &__result {
        &--right {
          font-size: 36px;
          min-width: 81px;

          span {
            font-size: 24px;
          }
        }

        &--left {
          text-align: left;
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }
}
</style>
